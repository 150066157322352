import React from 'react'
import Video from '../components/Video'

const Home = () => {
  
    return (
        <div>
         
          <Video />
        </div>
    ) 
  }


export default Home